<template>
<ModuleMaster 
    :requireSearchField="true"
    :requireAddButton="true"
    :requirePaginator="true"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="5"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :fnCallbackSearchItems="searchItems"
>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="true"
            :statusPropName="'isActive'"
        >
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction"/>
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalFormEditFamilia" ref="btnForm"></button>
<Familiesform ref="editForm" :mode="modeEditForm" :title="titleEditFormAction" :currentOpenedItem="currentFamiles" :fnCallBackPersistenceNotifier="handleItemSaved"/>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import CrudActions from "../../components/c-module-master/crud-actions.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../common/formaterToDatatable";
import Familiesform from "./Familiesform.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        Familiesform
    },
    setup() {
        //VARIABLES Y CONSTANTES
        const messageService = new SwalMessageService();
        let records = ref([]);
        let modeForm = ref("N");
        let titleEditForm = ref("Nuevo Familia");
        let currentPage = ref(1);
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const editForm = ref(null);
        const currentItem = ref(null);
        let textSearch = ref("");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo Registro",
        });

        const arrayTitleActions = [
            { action: "N", title: "Nueva Familia" },
            { action: "M", title: "Modificar Familia" },
            { action: "V", title: "Visualizar Familia" }
        ];

        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "code", HeadLabel: "Clave", Type: "text", VisibleInGrid: true },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "isActive", HeadLabel: "status", Type: "check", VisibleInGrid: false }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Familias", ["Catálogos"]);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "isActive");
        });

        const currentFamiles = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            if(["N", "V", "M"].some(a => a === action)) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
                if(action === "M" || action === "V") {
                    await getFamilesById(itemID);
                }
                if(btnForm.value) {
                    editForm.value.initDefaults();
                    btnForm.value.click();
                }
            } else if(action === "E") {
                let item = records.value.find(i => i.id === itemID);
                
                if(item) {
                    Swal.fire({
                        title: `Está a punto de ${ item.isActive ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                changeStatusItem(item);
                            }
                        }
                    );
                    
                }
                
            }
        }

        const changeStatusItem = async(item) => {
            let activate = !item.isActive;
            let url = `Families/${item.id}/${activate ? 'activate' : 'deactivate'}`;
            await ApiService.put(url).then(res => {
                item.isActive = activate;
                handleItemSaved(item);
                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }

        const getPagedItems = async() => {
            let criteriaSearch = `Criteria.Active=true${textSearch.value.trim() != "" ? `&Criteria.Name=${textSearch.value.trim()}` : ""}`;
            let url = `Families/GetPagedList?currentPage=${currentPage.value}&PageSize=${cantRecordsPage}&${criteriaSearch}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getFamilesById = async(id) => {
            ApiService.get(`Families/${id}`).then(res => {
                currentItem.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "N":
                    if(currentPage.value > 1) {
                        goToPage(1);
                    } else {
                        ApiService.get(`Families/${item.id}`).then(res => {
                            if(records.value.length == cantRecordsPage){
                                records.value = [res.data, ...records.value.slice(0, cantRecordsPage - 1)]
                            } else {
                                records.value = [res.data, ...records.value];
                            }
                            totalItems.value += 1;     
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        });                  
                    }
                    break;
                case "M":
                    updateRowItem(item);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = (item) => {
            let itemRow = records.value.find(ir => ir.id === item.Id);
            if(itemRow) {
                itemRow.name = item.name;
            }
        }

        const updateStatusRow = (idRow, status) => {
            let itemRow = records.value.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            editForm,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentFamiles,
            modeEditForm,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleItemSaved,
            searchItems
        }
        
    },
})
</script>
