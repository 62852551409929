export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          "INITIAL_DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento principal"])},
          "FINAL_DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo descuento"])}
        }
      }
    }
  })
}
